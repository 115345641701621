import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  @media (min-width: 767px) {
    width: 800px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 0%;
  width: 300px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const HeaderImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  width: 100%;
  @media (min-width: 900px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy and mint that shit.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting ur ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("yo somethin's buggin. try try again.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 11) {
      newMintAmount = 11;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg__.png" : null}
      >
        <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
              width: "88%",
            }}
          > <a href="https://discord.gg/ajrvGfCA" target="_blank">DISCORD</a> | <a href="https://twitter.com/fctbct" target="_blank">TWITTER</a></s.TextDescription>
          <s.SpacerLarge />
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerSmall />
        
        <s.Container jc={"center"} ai={"center"} style={{ width: "100%" }}>
            <HeaderImg
              alt={"tldr"}
              src={"/config/images/degens.jpg"}
              
            />       
            </s.Container>

            <s.TextDescription
            style={{
              textAlign: "left",
              color: "var(--primary-text)",
              width: "100%",
            }}
          >
  Blow the trumpets and bang the drums. Rejoice, for DEEP STATE DEGENS VOLUME 1 is here. 
  <s.SpacerLarge />
  The inaugural release from FCTBCT. A PFP collection of 11,111 with a max mint of 11 at 0.01111 ETH each. 
  <s.SpacerLarge />
  The first photographic punks-style generative avatar project that we're aware of, too.     
  <s.SpacerLarge />
  We've got a loooooong ass manifesto explaining it all below. 
  <s.SpacerLarge />
  But you don't read--So the tl;dr is basically: 
  <s.SpacerLarge />
  we have a big vision and the shit knocks. 
  <s.SpacerLarge />
  Mint and prosper, anon.
  <s.SpacerLarge/>
  </s.TextDescription>
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/3.gif"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/2.gif"} />
          </s.Container>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/1.gif"} />
          </s.Container>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/4.gif"} />
          </s.Container>
          </ResponsiveWrapper>
        
        
        

        
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/release.gif"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 0,
              border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} IS {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                
                <s.SpacerSmall />
                
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Cough up teh bread.
                    </s.TextDescription>
                    
                    <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  11 MINTS MAX, ANON.
                  <s.SpacerSmall />
                </s.TextDescription>
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/release.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerLarge />
        
<HeaderImg alt={"MANIFESTO"} src={"/config/images/manifesto.jpg"}/> 
<s.TextDescription style={{textAlign: "left", color: "var(--primary-text)", width: "100%",}}>
<s.SpacerLarge />
    IT'S ALL SUCH FUCKING BULLSHIT. THAT'S THE FOUNDING PRINCIPLE. IT'S. ALL. SUCH. FUCKING. BULLSHIT.
<s.SpacerLarge />
    AND YET.
<s.SpacerLarge />
    AND YET.
<s.SpacerLarge />
    WE MAY FINALLY HAVE A WAY OUT. 
<s.SpacerLarge />
    BALAJI CALLS IT <a href="https://twitter.com/balajis/status/1451289071402184740" target="_blank">THE LEDGER OF RECORD</a>. GIGACHAD <a href="https://twitter.com/saylor/status/1408972697619550211" target="_blank">THE HIGHEST QUALITY ASSET</a>. 6529 <a href="https://twitter.com/punk6529/status/1445468399656595456" target="_blank">THE OPEN METAVERSE</a>. MLK CALLED IT <a href="https://www.youtube.com/watch?v=Oehry1JC9Rk" target="_blank">THE PROMISED LAND</a>. SCOTT ADAMS CALLS IT <a href="https://youtu.be/fMBBy7jHLkU?t=390" target="_blank">THE GOLDEN AGE</a> AND ECKHART TOLLE <a href="https://www.amazon.com/New-Earth-Awakening-Purpose-Selection/dp/0452289963/" target="_blank">THE NEW EARTH</a>.
<s.SpacerLarge />
    WE CALL IT ESCAPE FROM THE MATRIX TO A FUTURE THAT'S WORTH LIVING IN.
<s.SpacerLarge />
    THROUGHOUT HISTORY, THERE COMES A TIME WHERE WE REALIZE THAT WE ARE COLLECTIVELY BEHOLDEN TO DUMBASS LEGACY MORONS AND THAT IT IS OUR DUTY TO TELL THEM TO KICK ROCKS, GTFO AND GO BACK THROUGH THE DAVOS DEMON PORTAL THEY CAME FROM SO WE CAN GET BACK TO BUIDLING.
    <s.SpacerLarge />
    <a href="https://www.youtube.com/watch?v=5qOpoZXxbBQ" target="_blank">VAINGLORIOUS ASS SISSIES.</a>
    <s.SpacerLarge />
    DETAILS FOLLOW.
<s.SpacerLarge />


<HeaderImg alt={"IT'S DIFFERENT"} src={"/config/images/different.jpg"}/>
<s.SpacerLarge />    
        We're well aware that most PFP projects have a perfunctory write-up with a bullshit roadmap with a lot of shit about donating to save the baby seals once they hit 50% mint. but we're built different. We made it all the way through <a href="https://youtu.be/eYpKIWVe3As?t=3211" target="_blank">Mook's 3rd</a>. We really bout that life.
    <s.SpacerLarge />
        Our inaugural collection is DEEP STATE DEGENS, A Bespoke Collection for the Discerning Degen. It is a PFP collection of 11,111 generative avatars built from 6 categories with thousands of variations for a total number of image combinations that can only be measured with exponential notation. 
    <s.SpacerLarge />
        The initial inspiration for the collection was twofold. 
    <s.SpacerLarge />
        First was the mind-numbing banality of this fiverr illustration aesthetic used by most existing PFP collections. Second and more importantly was the lack of CONTENT, the lack of MEANING. 
    <s.SpacerLarge />
        We dare you to spend time at the Borghese staring at Caravaggio's depiction of David holding the decapitated head of Goliath or be subsumed by Monet's Water Lilies at MOMA and not wonder if maybe we could be collectively aiming the bar a bit higher than endless cartoon animals. 
    <s.SpacerLarge />
        A bespectacled Indian once said, be the change you wish to see in the world. 
    <s.SpacerLarge />
        We like the quote. 
    <s.SpacerLarge />

<HeaderImg alt={"RARITY"} src={"/config/images/rarity.jpg"}/>

    <s.SpacerLarge />
    We have worked hard to make our rarities well balanced like so many Protoss, Terrans, and Zergs. We are long-time minters ourselves, and know well the joy of minting an ultra-rare or <a href="https://youtu.be/XI3xRzUjjFE?t=608" target="_blank">ripping open a Jordan rookie</a>. So we know how important it is for the long term success of the project that these traits are well thought out. 
    <s.SpacerLarge />
    In general, for each of the six categories we have four rarities: common, unique, rare, and holy shit. 
    <s.SpacerLarge />
    In addition, we have worked to give these rarities relevance to how rare they are in real life. Rather than arbitrarily making 1% of the backgrounds green, maybe you'll win a golden ticket Charlie Bucket.
    <s.SpacerLarge />

<HeaderImg alt={"ORIGINS & OVERVIEW"} src={"/config/images/origins.jpg"}/>

    <s.SpacerLarge /> 
        For Conspiracy Theorists, By Conspiracy Theorists (FCTBCT) is an educational DAO sitting at the intersection of hip-hop, conspiracy, and spirituality. 
    <s.SpacerLarge /> 
        What connects these cultures is RABBIT HOLES. 
    <s.SpacerLarge /> 
        Whether it's the Podesta emails or the Tuskegee experiments, wordplay from Daylyt or the decades-to-discover Angela Davis vocal sample Primo used on the "Mathematics" chorus, the relationship betweeen the Pyramids at Giza and the Orion constellation, or Taproot and EIP-1559, each of these involves diving down deep and deeply rewarding rabbit holes.
    <s.SpacerLarge /> 
        Interestingly, we have noted the tech to hip-hop to conspiracy to spirituality pipeline. 
        <s.SpacerLarge /> 
        1️⃣ Hip-hop is built on tech--there's no hip-hop without Roland and Akai and E-mu and Ensoniq and Ampex and Technics.
        <s.SpacerLarge />
        2️⃣ Hip-hop leads to conspiracy--whether you get it from Dead Prez, Brand Nubian, Jay-Z, Public Enemy, Immortal Technique, Jedi Mind Tricks, or Capital Steez.
        <s.SpacerLarge />
        3️⃣ Once you're there, both Sam Tripoli and the Blastmaster himself have noted that conspiracy eventually dead ends into spirituality. 
    <s.SpacerLarge /> 
        We fully plan to be a major player at this intersection. Consider this a planting of the flag.
    <s.SpacerLarge /> 
        gm.
    <s.SpacerLarge /> 

<HeaderImg alt={"DISTRIBUTION"} src={"/config/images/distribution.jpg"}/>

    <s.SpacerLarge />
    The contract was deployed onto Ethereum mainnet on November 11, 2021 with a reveal date set for December 21, 2021. Both 11/11 and 21/12 are highly symbolic dates that were intentionally chosen, as was the 40-day gap between the two. 
    <s.SpacerLarge />
    The same 40 days that Siddartha Gautama spent fasting under a bodhi tree and Yeshua of Nazareth spent fasting in the desert, both firmly determined to see beyond the veil.
    <s.SpacerLarge />
    We make these notes to say that we have tried to be as intentional as possible with every aspect of this collection, from content to contract. No joke, even the gas fee to deploy was 111 gwei. 
    <s.SpacerLarge />
    With pricing, we have always seen ourselves as <a href="https://www.youtube.com/watch?v=iMewtlmkV6c" target="_blank">working class heroes</a>, and want to make our collection available to as broad a range of collectors as possible. Watching so many low quality projects being sold for 0.08 ETH or higher also made us want to run in exactly the opposite direction. Don't forget that Punks were offered for free. That's legit.
    <s.SpacerLarge />
    This esoteric approach has led us again and again back to the stealth mint. Stealth is the very cloth we are cut from. Before there was "street art," you got props from getting up in a spot you weren't supposed to--or weren't even supposed <a href="https://youtu.be/OwHbZ1lRGNY?t=13" target="_blank">to be able to</a>. Hood fame is something you can't put a price on no matter how much $USTT you collect. 
    <s.SpacerLarge />
    This is what our OG's taught us and we take it as our duty to uphold and maintain the lineage as society mutates from analog to digital. 
    <s.SpacerLarge />
    For the edumucated, stealth and secrecy have a long history in the finer arts as well, whether it's the modern superstar Banksy or the triple OG anons Shakespeare, Caravaggio and the Brontë sisters.
    <s.SpacerLarge />
    Lastly, the stealth mint enables us to sneakily offer the street cred of catching the mint and HODLing. Call it proof-of-being-up-on-game, visible to anyone with your public address. 
    <s.SpacerLarge />
    ...On some how you like that <a href="https://shinhyungchoi.medium.com/proof-of-provenance-why-nfts-can-be-valuable-1489ed1395fd" target="_blank">provenance</a>.
    <s.SpacerLarge />

<HeaderImg alt={"VISION"} src={"/config/images/vision.jpg"}/>                      

    <s.SpacerLarge />
    <a href="https://twitter.com/then_there_was/status/1460677714831024131" target="_blank">Warren Buffet says</a> the market system does a great job rewarding entertainers, but not educators, even though their work might be more valuable in the long run.
    <s.SpacerLarge />
    If we trace this back to first principles, this is as much a problem of <a href="https://twitter.com/TrungTPhan/status/1463545118556712963?s=20" target="_blank">incentives</a> as anything else. There's no inherent reason for people--or at least our people--to prefer entertainment to education. 
    <s.SpacerLarge />
    So as a way to incentivize the production of best-in-class educational material, we plan to utilize giveaways in a very different way than other projects. 
    <s.SpacerLarge />
    Rather than contributing to more spam of tagging and retweeting, we will reward those who are able to create the very dopest educational threads, memes, and videos.
    <s.SpacerLarge />
    Our contract minted 11 initial giveaway NFTs, held publicly in <a href="https://unstoppabledomains.com/d/fctbct.nft" target="_blank">FCTBCT.nft</a>. We will mint or purchase up to 9% of the initial supply for the sole purpose of giveaways. We intend to be the leader and innovator in the giveaway space, utilizing NFT giveaways to crowdsource intelligence for the betterment of all. 
    <s.SpacerLarge />
    Through these giveaways, we will incentivize the steel-manning of information outside the Overton window, lost to the sands of time, or too far down the rabbit hole and thus overlooked and improperly understood. Dissident scholars that we are, we will also reward the steel-manning of mainstream positions, always with the aim of being <a href="https://www.lesswrong.com/posts/9KvefburLia7ptEE3/the-correct-contrarian-cluster" target="_blank">contrarian but correct</a>, not simply the flipside of the NPC. 
    <s.SpacerLarge />
    While this knowledge is doing its work increasing the collective intelligence of humanity, it simultaneously increases awareness of our project and thus the value of the collection. 
    <s.SpacerLarge />
    WGMI, anon.
    <s.SpacerLarge />
    Examples would include:
<ul><s.SpacerLarge /><li>
    1️⃣st tier (officially declassified and sanctioned): Gulf of Tonkin, Reichstag fire, Operation Northwoods, Project Paperclip, Epstein, UFOs, Heart rate variability, Dogon tribe, illegal drone strikes
</li><s.SpacerLarge /><li>
    2️⃣nd tier (verifiable and robust circumstantial evidence): Dr. Sebi, Building 7, Pedogate, thermite, Atlantis, non-local consciousness, telepathine & DMT & ayahuasca, heart-brain, remote viewing, area 51, human trafficking for organ harvesting
</li><s.SpacerLarge /><li>
    3️⃣rd tier (anecdotal and circumstantial evidence): the Annunaki, the Secret Space Program, ET races such as the Zetas, Pleiaidians, Arcturians, Sirians, and Lyrans, the DMT realm, interdimensional entities, mantids, animal human hybrids, Flat Earth, moon landing hoax, Mark of the Beast, telepathy and psychic phenomena, energy healing, divination systems such as gematria, astrology, and the I Ching, adrenochrome
</li><s.SpacerLarge /><li>
    📜 Information lost to the sands of time: Gobekli Tepe, Nazca lines, worldwide Pyramids, underwater coastal ruins, empires from the Merovingians to the Tartarians, monetary history, artistic innovations from the rediscovery of linear perspective to Escher's tilings of the plane
</li><s.SpacerLarge /><li>
    ❌ Information improperly understood: Bitcoin, 432hz tuning, Permaculture, Earthships, Fasting, natural healing, cymatics, power of intention, linguistic innovation in hip-hop through lyricism and wordplay
</li><s.SpacerLarge /></ul>
    In our Discord HODLers have immediate access to both highly moderated, high IQ, high signal-to-noise discussion forums on topics of interest, as well as governance forums where giveaway subjects, the direction of future launches and overall project vision will be debated and voted upon. 
  <s.SpacerLarge /> 

<HeaderImg alt={"PRINCIPLES"} src={"/config/images/principles.jpg"}/>  

  <s.SpacerLarge />
    1️⃣ Everything we do, in the words of our favorite mensch garyvee, is about our legacy that we will leave to the <a href="https://www.dailymail.co.uk/news/article-3408500/It-super-primal-sexual-experience-Group-women-make-extraordinary-claim-hybrid-children-fathered-ALIENS-live-giant-spaceships-say-best-sex-ever.html" target="_blank">hybrid children</a> that are to follow. Most importantly we must teach the children to be legit. 
    <s.SpacerLarge />
    2️⃣ We are believers in <a href="https://www.youtube.com/watch?v=PuhlvJ__9GE" target="_blank">low time preference</a> and <a href="https://bitcointalk.org/index.php?topic=375643.0" target="_blank">HODLing</a> and want to reward this behavior with unexpected delightful gifts that will come with no notice whatsoever on some good things come to those who wait shit.
    <s.SpacerLarge />
    3️⃣ We want to bring a deeper level of truth and integrity to the world and are attracted to Web 3 and to DAO's specifically for this reason. We all know by now that it's high time to <a href="https://medium.com/@georgealexpopescu/internet-3-0-decentralizing-everything-b463897b0e10" target="_blank">decentralize everything</a> and as Julian Assange said, sunshine is the best disinfectant.
    <s.SpacerLarge />
    4️⃣ We're playing the longest game. The game of <a href="https://www.youtube.com/watch?v=AhE2tGJ-YiI" target="_blank">Caz</a>, of <a href="https://youtu.be/C5KOIijlMiU" target="_blank">Kane</a>, of <a href="https://youtu.be/kCBzFV54msE" target="_blank">Jay</a>. And <a href="https://twitter.com/beaniemaxi/status/1443216534877388802" target="_blank">Beanie taught us well</a>. 
    <s.SpacerLarge />
    
<HeaderImg alt={"MISSION"} src={"/config/images/mission.jpg"}/>     
<s.SpacerLarge />
    Before Bill Gates set out to become the Antichrist and enslave humanity, he had a far more admirable, yet still quite ambitious goal: a computer on every desk.
    <s.SpacerLarge />
    With this as our inspiration, we will set out an equally audacious goal: a wide and fully open Overton Window. 
    <s.SpacerLarge />
    As Graham Hancock so eloquently stated, <a href="https://youtu.be/pDmP8WQ2IYw?t=203" target="_blank">we are like a species with amnesia</a>. As our amnesia recedes, we could enter a new flourishing, a cultural and artistic Renaissance that appropriates every culture in all of our histories to create something new as we launch into inner/outer space to rediscover our galactic heritage. 
    <s.SpacerLarge />
    We dream that one day, children will know as much about Gobekli Tepe and MK Ultra and the Annunaki as they do about the American Revolution and the Magna Carta and the Great Wall of China. We imagine all the people doing Wim Hof breathing, with knowledge of the power of intention as commonplace as Coca Cola and canola oil.
    <s.SpacerLarge />
    Basically, we just wanna find the others who can see and vibe out.
    <s.SpacerLarge />
<HeaderImg alt={"PLAN"} src={"/config/images/plan.jpg"}/>  

    <s.SpacerLarge />
    To <a href="https://quotefancy.com/quote/1635337/Ben-Horowitz-Planning-is-valuable-tho-the-plan-is-usually-useless" target="_blank">paraphrase MC Tic Toc</a>, there's zero chance our plan will happen but there's zero chance we'll succeed without one. So here goes nothing. 
    <s.SpacerLarge />
    Let's get one thing abundantly clear at the outset. One thing you will never, ever find in any of our plans is a "roadmap." We hate roadmaps and the midwits who create them. 
    <s.SpacerLarge />
    So in the conract, we have pre-mined the first 111 NFTs which will serve as the basis for a DAO treasury. These will not once, not ever be sold or transferred and will always be publicly and visibly held in the wallet at <a href="https://unstoppabledomains.com/d/fctbct.nft" target="_blank">FCTBCT.nft</a>. 
    <s.SpacerLarge />
    As the value of the collection grows, we could either stake these NFTs to provide yield or issue an NFT-backed governance token to steer the very profound governance decisions we will face as the project evolves.
    <s.SpacerLarge />
    For instance, pause and take note of the fact that the original homemade internet-born viral documentaries were "Loose Change" and "Zeitgeist." You can make a case that conspiracies are the porn of knowledge. Always at the leading edge, hugely popular, and yet nevertheless completely unacknowledged. 
    <s.SpacerLarge />
    It is possible that in the same way that the <a href="https://twitter.com/cdixon/status/865424180816224256" target="_blank">token is the native business model</a> of open source software, so can the NFT bring a sustainable funding model for the production of Overton-unfriendly educational content that would receive neither proper production nor promotion from mainstream distributors. Not only can more of this content thus be producted, but the profits can go into the hands of both creators as well as incentivize the early adopters and distributors--aka anons.
    <s.SpacerLarge />
    In general, we believe the conspiracy ecosystem is significantly undervalued due to its current unpalatability and once well-funded could be a major source of both innovation and human freedom, helping us stay far far away from the gulags, be they physical, digital, or mental. 
    <s.SpacerLarge />
    Another possible direction, while seemingly outlandish--the <a href="https://medium.learningbyshipping.com/is-a-toy-21312a5c8aea#.16gu092mt" target="_blank">next next thing begins as a toy</a>--is that FCTBCT could become a hybrid hedge fund/venture capital firm. We could perhaps call it Vendetta Capital.
    <s.SpacerLarge />
    The reason for this is that we may need to invest in or create a parallel economy Opensea competitor. It is clear with the delisting of <a href="https://phunks.medium.com/the-cryptophunks-manifesto-785c7348e558" target="_blank">CryptoPHUNKS</a> and <a href="https://www.breitbart.com/tech/2021/11/22/crypto-censorship-nft-marketplaces-opensea-rarible-ban-works-from-political-cartoonist-stonetoss/" target="_blank">Flurks</a> that Opensea and Rarible may not be utopian, decentralized web 3 entities but instead just big tech 2.0. If so, we will necessarily have to see what our open protocols are really made of and rugpull the virtue signallers to build a first-amendment compatible NFT platform.
    <s.SpacerLarge />
    On a lighter note, FCTBCT could just as easily end up being a content studio that pays dividends to hodlers. Roy Price, founder of Amazon Video <a href="https://twitter.com/RoyPrice/status/1462553874888151044" target="_blank">wrote</a> about how such a tokenized crowdfunding model could succeed. 
    <s.SpacerLarge />
    To give examples, we could imagine funding:
     <s.SpacerLarge />
     ✅ Documentaries funded through NFT collections by people with already proven track records like Jon du Toit, Amanda Milius, Sean Stone, Amazing Polly, Mouthy Buddha, or James O'Keefe.
     <s.SpacerLarge />
     ✅ Music compilations sold as limited NFT's complete with digital merch NFT's on uplifting themes like Hollywood Child Sex Trafficking by artists such as Jedi Mind Tricks, RA the Rugged Man, Aesop Rock, Bryson Gray, Killah Priest, Goodie Mobb, Tom MacDonald, Bone Thugs, Loza Alexander, Dead Prez, Hi-Rez, Daylyt, and Immortal Technique.
     <s.SpacerLarge />
     ✅ "Masterclass"-style virtual workshops with NFT credentialing featuring luminaries like David Icke, Alex Jones, Billy Carson, Jordan Maxwell, Michael Tellinger, Charles Eisenstein, Steven Greer, Robert Edward Grant, Graham Hancock, and Clif High.
     <s.SpacerLarge />
     ✅ A permanent, uncensorable Conspirapedia built on top of ENS, stored permanently on ARWeave with the best evidence--built from threads created by past giveaway winners--for subjects they bring out the BanHammer 9000 for: Pedogate, Hollywood Thelemites, Satanic Pedophilia, and International Child Sex Trafficking. 
     <s.SpacerLarge />
    The floor is lava and the sky is the limit. 
     <s.SpacerLarge />
    With that said and to revisit the wisdom of MC Tic Toc, most likely we end up doing none of these and instead do something that no one can yet imagine because it doesn't even exist yet. 
    <s.SpacerLarge />
    RIP Avicii.
    <s.SpacerLarge />


<HeaderImg alt={"TEAM"} src={"/config/images/team.jpg"}/>

    <s.SpacerLarge />
    We are a group of 11: anarchist, Asian, AI, alien, actuary, acrobat, artist, aristocrat, assassin, asshole and apple farmer who have chosen to let the <a href="https://www.goodreads.com/quotes/48531-make-your-ego-porous-will-is-of-little-importance-complaining" target="_blank">work speak for itself</a>.
    <s.SpacerLarge />
    We're all beautiful in the metaverse, anon. 
    <s.SpacerLarge />

<HeaderImg alt={"RISKS"} src={"/config/images/risks.jpg"}/>

    <s.SpacerLarge />
    You might catch Daylyt in the quiet room. Yikes
    <s.SpacerLarge />
    Seriously though, we would be remiss to not mention the risk inherent in a project like this, namely that they will come after us with the full force of their virtue signalling.
    <s.SpacerLarge />
    As cosmic beings, we love the generativity of programatically mixing and matching the symbols that man has created over millenia, across uncountable cultures. <a href="https://en.wikipedia.org/wiki/Man_and_His_Symbols" target="_blank">Man and His Symbols</a> has been a source of endless fascination for many of the greatest minds that humanity has managed to produce across ages and epochs.
    <s.SpacerLarge />
    The lizards though. Yes, the lizards seem steadfastly determined to miss forest for trees, myopically scanning the horizon for any "bad symbols" that they have solemnly vowed to disappear like so many kulaks. Never ones for laughter and mirth or nuance and context, the lizards may very well try to ban us and everything we hold dear.
    <s.SpacerLarge />
    We accept this possibility and to be honest, we kind of want to see what these decentralized technologies are really made of. Can truth, beauty, and transparency really shine or will the <a href="https://twitter.com/bitstein" target="_blank">toxic</a> <a href="https://twitter.com/pierre_rochard" target="_blank">Bitcoin</a> <a href="https://twitter.com/saifedean" target="_blank">maxis</a> be right again and this whole web 3 revolution is just a clever ploy for <a href="https://dealbook.nytimes.com/2014/01/21/why-bitcoin-matters/" target="_blank">Marc</a> & <a href="https://techcrunch.com/2012/06/14/the-struggle/" target="_blank">Ben</a> and <a href="https://onezero.medium.com/why-decentralization-matters-5e3f79f7638e" target="_blank">Chris</a> & <a href="https://www.nytimes.com/2021/10/15/opinion/ezra-klein-podcast-katie-haun.html" target="_blank">Katie</a> and <a href="https://www.usv.com/writing/2018/03/cryptokitties-2/" target="_blank">Fred</a> & <a href="https://www.usv.com/writing/2017/05/protocol-labs/" target="_blank">Brad</a> & <a href="https://worldaftercapital.org/" target="_blank">Albert</a> and <a href="https://twitter.com/search?q=fuck%20jason%20calacanis&src=typed_query&f=live" target="_blank">Jason</a> & <a href="https://davidsacks.medium.com/the-speech-cartel-b3f5555f7787" target="_blank">David</a> & <a href="https://twitter.com/amitranjan/status/1248559196628381696" target="_blank">Chamath</a> and <a href="https://tokenist.com/funding-rounds-in-focus-ftx-valuation-rises-to-25-billion/" target="_blank">Alfred</a> & <a href="https://www.youtube.com/watch?v=GRCjazKSSjo" target="_blank">Roelof</a> to make another few billion as we descend into a digital hell of star-studded struggle sessions in VR.
    <s.SpacerLarge />
    We're long humanity, long the Streisand Effect, and long you anon. So we hope we're right but we can't lie. We might be wrong. Maybe our destiny is cartoon animals and crickets for breakfast with an anal swab health checkup for good measure. Either way, this is not investment advice, and just remember that <a href="https://youtu.be/tzOOCnkUlnA?t=16" target="_blank">them stakes is high</a>. 
    <s.SpacerLarge />


</s.TextDescription>
</s.Container>
</s.Screen>
);
}

export default App;
